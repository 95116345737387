<template>
  <div class="ant-card" id="member_manage">
    <div class="ant-card-body" v-if="$route.meta.isShow">
      <div class="head-box">
        <div class="table-operator">
          <router-link to="/marketingCenter/memberManage/memberAction/0">
            <a-button v-show="isShowAdd" icon="plus" type="primary" >新增</a-button>
          </router-link>
        </div>
        <a-form
          layout="inline"
          :model="searchForm"
          @submit="handleSearch"
          @submit.native.prevent
        >
          <a-form-model-item label="会员状态">
            <a-select
              style="width: 200px"
              v-model="searchForm.status"
              @change="statusChange"
            >
              <a-select-option v-for="item in typeList" :key="item.key">
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item>
            <a-input v-model="searchForm.keyword" placeholder="请输入会员名称">
              <a-icon
                slot="prefix"
                type="search"
                style="color: rgba(0, 0, 0, 0.25)"
              />
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button icon="search" type="primary" html-type="submit"
              >搜索</a-button
            >
          </a-form-model-item>
        </a-form>
      </div>
      <div class="content-box">
        <a-table
          :columns="columns"
          :data-source="tableList"
          :loading="tableLoading"
          :customRow="customRow"
          :pagination="false"
        >
          <span slot="number" slot-scope="number, record, index">
            <span>{{ index + 1 }}</span>
          </span>
          <img
            v-viewer
            class="table-coverImg"
            slot="coverImg"
            slot-scope="coverImg, record"
            :src="record.coverImg"
          />
          <span slot="subtitle" slot-scope="text, record">
            {{ record.subtitle == "" ? "——" : record.subtitle }}
          </span>
          <span slot="status" slot-scope="text, record">
            <template v-if="record.forbkeydenStatus == 1">
              <a-badge status="success" text="使用中" class="success-text" />
            </template>
            <template v-if="record.forbkeydenStatus == 2">
              <a-badge status="error" text="已停用" class="error-text" />
            </template>
          </span>
          <span slot="action" slot-scope="text, record">
            <a
              class="mr20"
              v-if="record.forbkeydenStatus == 1 && isShowDisbled"
              @click="handleOption(record.key, record.forbkeydenStatus)"
              >停用</a
            >
            <a
              class="mr20"
              v-if="record.forbkeydenStatus == 2 && isShowEnable"
              @click="handleOption(record.key, record.forbkeydenStatus)"
              >启用</a
            >
            <router-link
              v-show="isShowEdit"
              class="mr20"
              :to="`/marketingCenter/memberManage/memberAction/${record.key}`"
              >编辑
            </router-link>
            <router-link
              v-show="isShowRecord"
              class="btn-record mr20"
              :to="`/marketingCenter/memberManage/memberDetail/${record.memberNo}`"
              >
              使用详情
            </router-link>
            <a
              id="copy_memberNo"
              :data-clipboard-text="record.memberNo"
              type="primary"
              class="margin_right20"
              @click="copyMemberNo"
            >
              复制商品ID
            </a>
             <a
              id="copy_memberNo"
              type="primary"
              class="margin_right20"
              @click="appShow(record.memberNo)"
            >
              app显示控制
            </a>
          </span>
        </a-table>
        <!-- 分页功能 -->
        <MyPagination
          :count="count"
          :pageNo="searchForm.pageNo"
          @showSizeChangeFn="showSizeChangeFn"
          v-show="tableList.length > 0"
        ></MyPagination>
      </div>
         <a-modal
      title="新建/编辑标签"
      :width="640"
      :visible="visible"
      :confirmLoading="confirmLoading"
      okText="确定"
      cancelText="取消"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="tapForm"
        :model="tapform"
        :label-col="{ span: 7 }"
        :wrapperCol="{ span: 13 }"
      >
        <a-form-model-item label="标签名称" prop="labelText">
          <!-- <a-input v-model="tapform.labelText">
            <div slot="addonAfter">{{ tapform.labelText.length }}/6</div>
          </a-input> -->
             <a-radio-group
            name="radioGroup"
            :value="tapform.showflag"
            @change="pointTypeChange"
          >
            <a-radio :value="1"> 显示 </a-radio>
             <a-radio :value="0"> 不显示 </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view v-else />
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { Modal, message } from "ant-design-vue";
import MyPagination from "@/components/pagination/MyPagination";
import {
  MemberListV2Api,
  MemberEnableApi,
  MemberDisableApi,
  MemberSortApi,
  GetMemberAppShowApi,
  SetMemberAppShowApi
} from '@/request/api/marketingCenter'
import { throttle } from '../../utils/tools'
import { codeFn } from '@/utils/tools'
export default {
  components: { MyPagination },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      tapform: {
       showflag:1
      },
      searchForm: {
        pageNo: 1,
        pageSize: 20,
        keyword: "",
        status: 0,
      },
      count: 0,
      columns: [
        {
          title: "序号",
          dataIndex: "number",
          key: "number",
          scopedSlots: { customRender: "number" },
        },
        {
          title: "封面图",
          dataIndex: "coverImg",
          key: "coverImg",
          scopedSlots: { customRender: "coverImg" },
        },
        { title: "会员名称", dataIndex: "memberName", key: "memberName" },
        {
          title: "副标题",
          dataIndex: "subtitle",
          key: "subtitle",
          scopedSlots: { customRender: "subtitle" },
        },
        { title: "会员价格", dataIndex: "price", key: "price" },
        { title: "会员有效期", dataIndex: "days", key: "days" },
        {
          title: "状态",
          dataIndex: "forbiddenStatus",
          key: "forbiddenStatus",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      typeList: [{ key: 0, value: "全部状态" }],
      tableList: [],
      tableLoading: false,
      sourceObj: {}, // 表格源对象
      sourceIndex: null, // 表格源对象索引
      targetObj: {}, // 表格目标对象
      targetIndex: null, // 表格目标对象索引
      isShowAdd: false, // 新增是否显示
      isShowEdit: false, // 编辑是否显示
      isShowEnable: false, // 启用是否显示
      isShowDisbled: false, // 停用是否显示
      isShowRecord: false, // 使用详情是否显示
    };
  },
  watch: {
    $route(to) {
      if (to.path === "/marketingCenter/memberManage") {
        this.MemberList();
      }
    },
  },
  created() {
    let dictionaryObj = JSON.parse(localStorage.getItem("dictionaryObj"));
    dictionaryObj.disableOrEnabled.forEach((item) => this.typeList.push(item));
    this.MemberList();
    if (codeFn("/admin/member/add")) this.isShowAdd = true;
    if (codeFn("/admin/member/update/{id}")) this.isShowEdit = true;
    if (codeFn("/admin/member/enable/{id}")) this.isShowEnable = true;
    if (codeFn("/admin/member/disable/{id}")) this.isShowDisbled = true;
    if (codeFn("/admin/member/record")) this.isShowRecord = true;
  },
  methods: {
        // 改变打点类型
    pointTypeChange(e) {
      this.tapform.showflag = e.target.value;
    },
      // 标签提交
    handleOk() {
      this.confirmLoading = true;
       SetMemberAppShowApi({
          memberNo:this.memberNo,
          appShow:this.tapform.showflag
       }).then((res) => {
              if (res.code === 200) {
                this.visible = false;
                this.confirmLoading = false;
                this.$refs.tapForm.resetFields(); // 重置表格
                message.success("修改成功");
                this.MemberList();
              } else {
                setTimeout(() => {
                  this.confirmLoading = false;
                }, 2000);
              }
            });
    },
     // 标签取消或关闭
    handleCancel() {
      this.visible = false;
      this.$refs.tapForm.resetFields(); // 重置表格
    },
    appShow(no){
      this.memberNo = no
       GetMemberAppShowApi({memberNo:no}).then(res=>{
        if(res.code===200){
          this.tapform.showflag =Number(res.data.appShow) 
        }
      })
       this.visible = true;
    },
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#member_manage");
    },
    // 会员列表数据
    async MemberList() {
      this.tableLoading = true;
      const { code, data } = await MemberListV2Api(this.searchForm)
      this.tableLoading = false
      if (code !== 200) return
      this.count = data.count
      if (this.count === 0) {
        this.tableList = []
        return
      }
      const newData = data.data.map((item) => {
        return JSON.parse(JSON.stringify(item).replace(/id/g, "key"));
      })
      this.tableList = newData;
    },
    // 分页切换页数
    showSizeChangeFn(pageNo, pageSize) {
      this.searchForm.pageNo = pageNo;
      this.searchForm.pageSize = pageSize;
      throttle(this.MemberList());
    },
    // 搜索 - 会员状态
    statusChange(val) {
      this.searchForm.status = +val
      this.searchForm.pageNo = 1
      throttle(this.MemberList())
    },
    // 搜索
    handleSearch() {
      this.searchForm.pageNo = 1
      throttle(this.MemberList())
    },
    // 启用或禁用
    handleOption(id, status) {
      const _that = this;
      if (+status === 1) {
        Modal.confirm({
          title: "停用",
          content: "停用后，用户将不能在购买此会员，已购买的用户可以继续享受会员权益，是否停用？",
          okType: "danger",
          onOk() {
            _that.memberDisable(id)
          },
        });
      } else {
        _that.memberEnable(id)
      }
    },
    // 启用
    async memberEnable(id) {
      const { code } = await MemberEnableApi({ id: id })
      if (code !== 200) return
      throttle(this.MemberList())
    },
    // 停用
    async memberDisable(id) {
      const { code } = await MemberDisableApi({ id: id })
      if (code !== 200) return
      throttle(this.MemberList())
    },
    // 复制ID的按钮
    copyMemberNo() {
      let clipboard = new Clipboard("#copy_memberNo");
      clipboard.on("success", (e) => {
        message.success("复制成功", 1);
        clipboard.destroy(); // 释放内存
      });
      clipboard.on("error", (e) => {
        message.error("复制失败", 1); // 不支持复制
        clipboard.destroy(); // 释放内存
      });
    },
    // 表格拖动
    customRow(record, index) {
      return {
        props: {
          // draggable: 'true'
        },
        style: {
          cursor: "pointer",
        },
        on: {
          // 鼠标移入
          mouseenter: (event) => {
            var ev = event || window.event; // 兼容IE
            ev.target.draggable = true;
          }, // 开始拖拽
          dragstart: (event) => {
            var ev = event || window.event; // 阻止冒泡    // 兼容IE
            ev.stopPropagation(); // 得到源目标数据
            this.sourceObj = record;
            this.sourceIndex = index;
          }, // 拖动元素经过的元素
          dragover: (event) => {
            var ev = event || window.event; // 阻止默认行为     // 兼容 IE
            ev.preventDefault();
          }, // 鼠标松开
          drop: (event) => {
            // 兼容IE
            var ev = event || window.event; // 阻止冒泡
            ev.stopPropagation(); // 得到目标数据
            this.targetObj = record;
            this.targetIndex = index;
            this.tableList.splice(this.sourceIndex, 1);
            this.tableList.splice(this.targetIndex, 0, this.sourceObj);
            let arr = [];
            this.tableList.map((item, index) => {
              arr.push({ id: item.key, sort: index });
            });
            MemberSortApi(arr).then(({ code }) => {
              if (code == 200) {
                message.success("排序成功", 1);
              }
            });
          },
        },
      };
    },
  },
};
</script>

<style lang="less" scoped>
#member_manage {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
.head-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.table-operator {
  margin-right: 30px;
}
.table-coverImg {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 4px;
  margin-right: 16px;
}
.mr20 {
  margin-right: 20px;
}
/deep/.success-text .ant-badge-status-text {
  color: #52c41a;
}
/deep/.error-text .ant-badge-status-text {
  color: #9fa5af;
}
</style>

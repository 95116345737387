/*
 * @Author: dengqiuju@baidu.com
 * @Date: 2022-03-05 15:37:04
 * @Description: 营销中心相关接口
 */
// 营销中心相关接口
import request from '@/request/request'
import { domainName } from '@/config'

// 导出二维码使用记录
export const ExportCodeApi = domainName + '/admin/dealer/invitation-code/excel/excelDownload'
// 教师列表
export const InvitationCodeApi = (params) => request.post('/admin/dealer/invitation-code/queryDealerInvitationCodeResult', params);
// 新增邀请码
export const SaveInvitationCodeApi = (params) => request.post('/admin/dealer/invitation-code/saveDealerInvitationCode', params);
// 修改邀请码
export const UpdInvitationCodeApi = (params) => request.post('/admin/dealer/invitation-code/updDealerInvitationCode', params);
// 邀请码详情
export const CodeDetailApi = (params) => request.get('/admin/dealer/invitation-code/getDealerInvitationCodeResult', { params });
// 代理用户下拉栏
export const AgentSelectListApi = () => request.get('/admin/agent/select/users');
// 邀请码立即失效
export const InvitationCodeDisabledApi = (params) => request.get('/admin/dealer/invitation-code/disabled', { params });
// 二维码使用记录
export const RecordCodeApi = (params) => request.post('/admin/dealer/invitation-code/queryDealerInvitationRecordResult', params);

// 查询组件使用
export const QueryInvitationCode = (params) => request.post('/adminv2/dealer/queryInvitationCode', params);

// 邀请码 (分享)
export const ShareAppletCodeApi = (params) => request.get('/admin/dealer/invitation-code/shareAppletCode', { params });
// 线上码生成分享二维码
export const ShareEwmCodeApi = (params) => request.post('/admin/files/getQRCodeBase64', params);
// 线上码失效状态设置
export const InvitationCodeSetStatusApi = (params) => request.post('/admin/dealer/invitation-record/setStatus', params);

// 生成线下码
export const MemberInvitationCodeSaveApi = (params) => request.post('/admin/dealer/invitation-code/generateDealerInvitationCode', params);
// 线下码列表
export const MemberInvitationCodeListApi = (params) => request.post('/admin/dealer/invitation-code/queryAgentDealerInvitationRecordResult', params);
// 新版线上码
export const OnlineInvitationCodeListApi = (params) => request.get(`/admin/agent/goods/lists/${params.agentUserId}`, {params});


// 获取课程列表
export const CourseListApi = (params) => request.post('/admin/course/info/tab/queryCourseInfoAll', params);

// 会员管理 - 会员列表
export const MemberListApi = (params) => request.post('/admin/member/tab', params);
export const MemberListV2Api = (params) => request.post('/adminv2/member/lists', params);
// 会员管理 - 启用会员
// export const MemberEnableApi = (params) => request.post(`/admin/member/enable/${params}`);
export const MemberEnableApi = (params) => request.post(`/adminv2/member/enable`, params);
// 会员管理 - 停用会员
// export const MemberDisableApi = (params) => request.post(`/admin/member/disable/${params}`);
export const MemberDisableApi = (params) => request.post(`/adminv2/member/disable`, params);
// 会员管理 - 会员使用详情
export const MemberRecordApi = (params) => request.post(`/admin/member/record`, params);
// 会员管理 - 会员失效
export const MemberInvalidApi = (params) => request.get('/admin/member/invalidate', { params });
// 会员管理 - 新增会员
// export const MemberAddApi = (params) => request.post(`/admin/member/add`, params);
export const MemberAddApi = (params) => request.post(`/adminv2/member/add`, params);
// 会员管理 - 更新会员
export const MemberUpdateApi = (params) => request.post(`/adminv2/member/update`, params)
// export const MemberUpdateApi = (params, data) => request.post(`/admin/member/update/${params}`, data);
// 会员管理 - 会员详情
// export const MemberDetailApi = (params) => request.get(`/admin/member/detail/${params}`);
export const MemberDetailApi = (params) => request.post('/adminv2/member/detail', params);
// 会员排序
export const MemberSortApi = (params) => request.post(`/admin/member/sort`, params);
// 批量延期
export const MemberDelayApi = (params) => request.post(`/admin/member/delay`, params);




// 获取代理列表
export const GgentUsersApi = (params) => request.post(`/admin/agent/users`, params);
// 启用代理用户
export const GgentEnableApi = (params) => request.post(`/admin/agent/enable/${params.agentUserId}`, params);
// 禁用代理用户
export const GgentDisableApi = (params) => request.post(`/admin/agent/disable/${params.agentUserId}`, params);
// 代理用户下拉栏
export const SysUserTabApi = (params) => request.post(`/admin/sys/user/tab`, params);
// 代理等级下拉栏
export const GgentSelectlabelApi = (params) => request.get(`/admin/agent/select/label`, { params });
// 获取代理用户详情
export const GgentUserInfoApi = (params) => request.get(`/admin/agent/user/info/${params.agentUserId}`, { params });
// 新建代理
export const GgentSettingApi = (params) => request.post(`/admin/agent/setting`, params);
// 编辑代理
export const AgentUpdateApi = (params) => request.post(`/admin/agent/update`, params);
// 获取分润记录明细
export const AgentApi = (params) => request.post(`/admin/agent`, params);
// 导出分润明细
export const AgentExportApi = domainName + '/admin/agent/export';
// 获取代理用户分润的订单号
export const OrderCodesApi = (params) => request.get(`/admin/agent/order/codes/${params.agentUserId}`, { params });


// 获取代理配置列表
export const AgentConfigsApi = (params) => request.post(`/admin/agent/configs`, params);
// 获取代理配置详情
export const ProxyConfigDetailApi = (params) => request.get(`/admin/agent/config/${params.id}`, { params });
// 更新代理配置信息
export const AgentConfigsUpdateApi = (params) => request.post(`/admin/agent/config/update/${params.id}`, params);

// 获取优惠券列表
export const CouponManageListApi = (params) => request.post(`/adminv2/getCouponList`, params);
// 更改优惠券状态
export const UpdateCouponStatusApi = (params) => request.post(`/adminv2/upCouponStatus`, params);
// 新增优惠券接口
export const AddCouponApi = (params) => request.post(`/adminv2/addCoupon`, params);
// 修改优惠券接口
export const EditCouponApi = (params) => request.post(`/adminv2/editCoupon`, params);
// 获取优惠券详情
export const GetCouponDetailApi = (params) => request.post(`/adminv2/getCouponInfo`, params);
// 优惠券使用记录列表
export const CouponUsedDetailApi = (params) => request.post(`/adminv2/getCouponUseInfo`, params);
//获取会员卡状态
export const GetMemberAppShowApi = (params) =>
  request.post(`/adminv2/getMemberAppShow `, params);
         //设置会员卡状态
export const SetMemberAppShowApi = (params) =>
         request.post(`/adminv2/setMemberAppShow `, params);

// 任务中心
// 任务列表
export const getShopTaskList = (params) => request.post('/adminv2/shoptask/lists', params)
// 禁用任务
export const removedShopTask = (params) => request.post('/adminv2/shoptask/removed', params)
// 任务详情
export const getShopTaskDetailAPI = (params) => request.post('/adminv2/shoptask/getOne', params)
// 编辑任务
export const editShopTaskAPI = (params) => request.post('/adminv2/shoptask/edit', params)